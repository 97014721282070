import React, {Component} from "react"
import { Link } from "react-router-dom";

const divStyle = {
    width: "5rem",
    height: "5rem",
    position: "relative",
    display: "inline-block",
    margin: "0.5rem",
    backgroundColor: "white",
    padding: "10px",
    border: "5px solid black",
    borderRadius: "25px",
    textAlign: "center"
}

const submitDivStyle = {
    width: "16rem",
    height: "5rem",
    position: "relative",
    display: "inline-block",
    margin: "0.5rem",
    backgroundColor: "red",
    color: "white",
    padding: "10px",
    border: "5px solid black",
    borderRadius: "25px",
    textAlign: "center"
}

class NumericButton extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }
    
    render() {
        return (
            <div style={((this.props.isSubmit || false) ? submitDivStyle : divStyle)} onClick={(event) => {event.stopPropagation(); this.props.onClick(this.props.number);}}>
                <span>{this.props.number}</span>
            </div>
        )
    }
}

export default NumericButton