import React, {Component} from "react"

class MenuView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/menu/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Menukaart</h2>
                <p>Dit is onze standaard menukaart. Afhankelijk van het event, locatie of organisator kunnen deze prijzen of het aanbod afwijken.</p>
                <p><img src="/images/menu/2024_menu_ijskarren_links.png" alt="menukaart ijs" style={{ width: '100%',}}></img></p>
                <p><img src="/images/menu/2024_menu_ijskarren_rechts.png" alt="menukaart ijs" style={{ width: '100%',}}></img></p>
                <p>Aan onze ijskarren kan je betalen met
                    <ul>
                        <li>Cash</li>
                        <li>Bancontact</li>
                        <li>Mastercard / Visa</li>
                        <li>Foubert cadeaubons</li>
                        <li>American Express</li>
                        <li>Apple pay</li>
                        <li>Google pay</li>
                        <li>Maestro</li>
                        <li>Discover</li>
                        <li>Diner's club</li>
                        <li>Union pay</li>
                        <li>Achteraf betalen / op factuur (enkel voor reservaties voor bedrijven, vooraf goedgekeurd)</li>

                    </ul>
                </p>
            </div>
        )
    }
}

export default MenuView