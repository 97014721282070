import React, {Component} from "react"
import { Link } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'

class UserMarker extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }
    
    render() {
        const myIcon = L.icon({
            iconUrl: "images/tracker/icon_pin.png",
            iconSize: [20,20],
            iconAnchor: [10, 10],
            popupAnchor: [-0, -10],
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null
        });

        return (
            <div className="iconhand">
                <Marker
                    icon={myIcon}
                    key={"usermarker"+this.props.trackid}
                    onClick={(event) => {this.props.onClick(this.props.trackid, this.props.Latitude, this.props.Longitude);}}
                    position={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    >
                    <Popup>
                        <b>Je bent hier!</b>
                        <p>Je was makkelijker te vinden dan Wally!</p>
                    </Popup>
                </Marker>
            </div>
        )
    }
}

export default UserMarker