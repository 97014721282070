import React, {Component} from "react"

class AboutView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Foubert ijskarren</h2>
                <p><b>Betaalmethodes</b></p>
                <p>Aan onze ijskarren kan je betalen met
                    <ul>
                        <li>Cash</li>
                        <li>Bancontact</li>
                        <li>Mastercard / Visa</li>
                        <li>Foubert cadeaubons</li>
                        <li>American Express</li>
                        <li>Apple pay</li>
                        <li>Google pay</li>
                        <li>Maestro</li>
                        <li>Discover</li>
                        <li>Diner's club</li>
                        <li>Union pay</li>
                        <li>Achteraf betalen / op factuur (enkel voor reservaties voor bedrijven, vooraf goedgekeurd)</li>

                    </ul>
                </p>
                <p><b>Historiek</b></p>
                <p>In 2012 begonnen we met onze eerste ijskar, in 2013 kwamen er 2 nieuwe bij! In 2014 begonnen we met ons “huis van de ijskarren” zodat we er in 2015 een reeks bij kunnen zetten! Ondertussen rijden we met 16 ijskarretjes door het ruime Waasland!</p>
                <p>We kregen/krijgen vaak de vraag om onze huisbereide producten ook op andere manieren te gaan verdelen. Onze ijskarren zijn daar 1 van onze antwoorden op (andere: webshop, bij bakkers & slagers , .. )</p>
                <p><b>Waar</b></p>
                <p>Regio Sint-Niklaas, Temse, Hamme, Dendermonde, Sint-Gillis-Waas, Lokeren, Zwijndrecht, Beveren, Moerbeke, Eksaarde, Daknam.</p>
                <p>Je kan al onze karren live volgen via deze app.</p>
                <p><b>Wanneer</b></p>
                <p>We rijden rond vanaf de eerste lentezon (in 2014 was dit begin maart!) tot midden oktober, afhankelijk van het weer.</p>
            </div>
        )
    }
}

export default AboutView