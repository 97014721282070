import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import {store} from "../../App";
import { makeCallOnOpen } from "../../Redux/TrackerActions";
import { connect } from 'react-redux';
import { setToast } from "../../Redux/OverlayActions";

class AmbiguousAddressView extends Component {
    constructor() {
        super();
        this.state = {
            placeid: null,
            latitude: null,
            longitude: null,
            adres: null,
            buttonEnabled: false,
            
            redirectToTracker: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.addressOptions == null) {
            store.dispatch(setToast({
                message: "Er liep iets fout! Probeer het opnieuw.",
                theme: "error",
            }));

            this.setState({
                redirectToTracker: true,
            });
        }
    }

    handleChange(event) {
        const {name, value, type, checked} = event.target;
        const obj = JSON.parse(value);
        this.setState({ 
            placeid: obj.placeid,
            latitude: obj.latitude,
            longitude: obj.longitude,
            adres: obj.adres,
        });

        this.setState({
            buttonEnabled: true,
        });
    }

    handleCancel(event) {
        event.preventDefault();
        this.setState({
            redirectToTracker: true,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        window.localStorage.setItem('placeid', this.state.placeid);
        window.localStorage.setItem('latitude', this.state.latitude);
        window.localStorage.setItem('longitude', this.state.longitude);
        window.localStorage.setItem('adres', this.state.adres);

        store.dispatch(makeCallOnOpen());

        this.setState({
            redirectToTracker: true,
        });
    }
    
    render() {

        if (this.state.redirectToTracker === true) {
            return <Redirect to='/' />
        }

        const options = (this.props.addressOptions != null ? this.props.addressOptions.map((option, index) => 
                <label key={index} className="customRadioBtn">
                    
                    <input 
                        type="radio" 
                        name="placeid"
                        value={JSON.stringify({placeid: option.placeid, latitude: option.latitude, longitude: option.longitude, adres: option.adres})}
                        checked={this.state.placeid === option.placeid}
                        onChange={this.handleChange}
                    />
                    <div className="row">
                        <div className="col-2 col-sm-1 text-center">
                        {this.state.placeid === option.placeid &&
                            <img className="pin_image" src={window.location.origin + '/images/pin_location.png'}/>
                        }
                        </div>
                        <div className="col-10 col-sm-11">
                            {option.adres}
                        </div> 
                    </div>
                    
                </label>
        ) : null);

        return (
            <div className="content container">
                <h2>Adres bevestigen</h2>
                <p>We zijn nog niet 100% zeker wat je adres juist is. Gelieve je juiste adres uit te lijst te selecteren.</p>
                <form onSubmit={this.handleSubmit} className="pt-2">
                    {options}
                    <input type="submit" className="btn btn-primary text-light col-12 mb-4" disabled={!this.state.buttonEnabled} value="Opslaan" />
                    <button style={{backgroundColor: "#7A7A7A"}} className="btn text-light btn-sm col-12 mt-2 mb-2" onClick={this.handleCancel}>Annuleer</button>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        addressOptions: state.tracker.addressOptions || null,
    }
}

export default connect(mapStateToProps)(AmbiguousAddressView);