import React, {Component} from "react"

const buttonStyle = {
    position: "fixed",
    left: "2rem",
    bottom: "2rem",
    margin: "0 auto",
    width: "3rem",
    zIndex: "1000",
}

class UserCenterButton extends Component {
    render() {
        const showButton = this.props.Tooltip != "true";

        return (
            <div>
                {showButton ? <img src='images\tracker\icon_pin_empty.png' alt='Centreer kaart' className='iconhand' style={buttonStyle} onClick={(event) => {event.stopPropagation(); this.props.onClick(this.props.trackid, this.props.Latitude, this.props.Longitude);}}/> : null }
            </div>
        )
    }
}

export default UserCenterButton