import React, {Component} from "react"
import { Link } from "react-router-dom";

class CookiePolicyView extends Component {
    render() {
        const backButton = ((window.localStorage.getItem('consent') || false) ? null : 
            <div>
                <div className="" style={{margin: "2rem"}}>
                    <Link to={"/aboutapp"} className="btn btn-primary btn-rounded" style={{color: 'white'}}>&lt; terug</Link>
                </div>
            </div>
        );

        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/aboutapp/header2.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>

                {backButton}
                <h2>Cookieverklaring BV FOUBERT IJS</h2>

<p><h3>1. Definities</h3></p>
<p>In deze Cookieverklaring wordt verstaan onder:</p>
<ol>
    <li><b>BV FOUBERT IJS</b>: : BV FOUBERT IJS met haar maatschappelijk zetel te Nobelspeelmanstraat 10, 9100 Sint-Niklaas en ingeschreven bij de Kruispuntbank van
Ondernemingen onder het nummer 0823.540.391</li>
<li><b>Website</b>: De website van BV FOUBERT IJS, https://www.foubert.eu/ en afgeleiden
ontwikkeld en uitgebaat door BV FOUBERT IJS</li>
<li><b>Persoonsgegevens</b>: Iedere informatie betreffende een geïdentificeerde of
identificeerbare natuurlijke persoon.</li>
<li><b>Cookies</b>: Kleine data- of tekstbestanden die bij het bezoek van een website op een
computer of een mobiel toestel worden geplaatst en vervolgens worden opgeslagen.</li>
</ol>

<p><h3>2. Cookies</h3></p>
<p>BV FOUBERT IJS streeft ernaar om u duidelijk te informeren over de wijze waarop Cookies
op de Website worden gebruikt en waarop ze op uw toestel (computer, tablet, smartphone,
etc.) worden opgeslagen om zo het bezoek aan de Website aangenamer te maken en beter
op uw behoeften en voorkeuren af te stemmen. BV FOUBERT IJS brengt de gegevens die
door Cookies worden verzameld niet in verband met de Persoonsgegevens die op andere
wijze worden ingezameld.
</p>

<p>Cookies helpen BV FOUBERT IJS om een bezoek aan de Website te optimaliseren door
een aantal nuttige taken uit te voeren, zoals het verbeteren van de beveiliging van de Website
en van de algemene gebruikerservaring. Zo kan de Website de pagina’s die u heeft bezocht
en uw voorkeuren (zoals taal) onthouden zodat u die niet bij ieder bezoek aan de Website
opnieuw dient in te vullen. Andere Cookies laten een analyse van bepaalde informatie toe
zoals het aantal bezoeken op de Website. Nog andere Cookies worden gebruikt voor
marketingdoeleinden.</p>

<p>Sessiecookies zijn Cookies waarvan de levensduur is beperkt tot de duur van de sessie, dit is
tot wanneer de browser wordt afgesloten en de verbinding met de Website afgebroken.
Daarnaast bestaan ook permanente Cookies.
</p>

<p>Eerste-partij-cookies worden geplaatst door de eigenaar van het domein van een website, in
dit geval BV FOUBERT IJS via de Website. Derde-partij-cookies worden door anderen dan
de eigenaar van het domein van een website ingesteld. BV FOUBERT IJS heeft daarom
geen toegang tot of controle over deze Cookies of andere manieren om gegevens te
verzamelen. Lees de respectieve privacyverklaringen van deze externe cookie-providers.</p>

<p>Volgende Cookies kunnen door BV FOUBERT IJS worden geplaatst:</p>
<table>
    <tr>
        <th>Naam</th>
        <th>Doel</th>
        <th>Levensduur</th>
    </tr>
    <tr>
        <td>PHPSESSID</td>
        <td>Opslaan winkelmandje, en andere gegevens</td>
        <td>Sessie</td>
    </tr>
    <tr>
        <td>TawkConnectionTime</td>
        <td>Bijhouden duur van de connectie voor de berichten applicatie Tawk.to.</td>
        <td>Sessie</td>
    </tr>
    <tr>
        <td>__tawkuuid</td>
        <td>Gebruikt door de berichten applicatie Tawk.to.</td>
        <td>6 maanden</td>
    </tr>
    <tr>
        <td>_ga</td>
        <td>Gebruikt voor statistieken door Google Analytics. Identificeert een unieke gebruiker</td>
        <td>2 jaar</td>
    </tr>
    <tr>
        <td>_gat</td>
        <td>Gebruikt voor het vertragen van de aanvragen.</td>
        <td>1 minuut</td>
    </tr>
    <tr>
        <td>_gid</td>
        <td>Gebruikt om gebruikers te onderscheiden.</td>
        <td>24 uur</td>
    </tr>
    <tr>
        <td>uid</td>
        <td>Zelfde als PHPSESSID</td>
        <td>Sessie</td>
    </tr>
    <tr>
        <td>spu_closing_[nr]</td>
        <td>Zorgt ervoor dat een popup niet herhaaldelijk wordt getoond.</td>
        <td>30 dagen</td>
    </tr>
</table>
<p>Volgende cookies kunnen door derden worden geplaatst:</p>
<p><b>Organisatie Tawk.to</b></p>
<table>
    <tr>
        <th>Naam</th>
        <th>Doel</th>
        <th>Levensduur</th>
    </tr>
    <tr>
        <td>__cfduid</td>
        <td>Identificeren van individuele klanten achter een gedeeld IP adres en
het voor het toepassen van security instellingen per klant.</td>
<td>24 uur</td>
    </tr>
    <tr>
        <td>Ss</td>
        <td>Gebruikt voor de berichten applicatie Tawk.to.</td>
        <td>Sessie</td>
    </tr>
    <tr>
        <td>tawkUUID</td>
        <td>Koppelt berichten applicatie van Tawk.to aan de foubert website.</td>
        <td>Onbepaalde duur</td>
    </tr>
</table>

<p><b>Organisatie Facebook</b></p>
<table>
    <tr>
        <th>Naam</th>
        <th>Doel</th>
        <th>Levensduur</th>
    </tr>

    <tr>
        <td>fr</td>
        <td>Gebruikt voor advertenties</td>
        <td>3 maanden</td>
    </tr>
</table>

<p>Om optimaal van de Website gebruik te kunnen maken, is het aangewezen dat u Cookies
activeert en/of aanvaardt op uw computer of mobiel toestel. Zonder ingeschakelde Cookies is
BV FOUBERT IJS niet in staat om een probleemloos bezoek aan de Website te
garanderen. De kans bestaat dat het bezoek wordt onderbroken of vertraagd en dat u geen
toegang heeft tot bepaalde delen van de Website of deze niet (optimaal) kan gebruiken.</p>

<p>Niettemin bent u vrij om via de browserinstellingen Cookies te verwijderen of te blokkeren.
Hoewel de meeste internetbrowsers standaard zijn ingesteld om Cookies te aanvaarden, kan
u de reeds geplaatste Cookies verwijderen en het plaatsen van nieuwe Cookies beperken of
blokkeren door de browserinstellingen aan te passen. De wijze waarop de browserinstellingen
kunnen worden aangepast, verschilt per browser. Meer informatie over de browserinstellingen
is te vinden op www.aboutcookies.org of in het "Help"-menu van de internetbrowser. </p>

<p>Indien u Cookies verwijdert of blokkeert, betekent dit wel dat u daarna uw voorkeuren bij ieder
bezoek opnieuw moet instellen en dat sommige delen van de Website niet (naar behoren)
werken.</p>

<p><h3>3. Verwerking persoonsgegevens</h3></p>
<p>Het is mogelijk dat door het gebruik van Cookies Persoonsgegevens worden verwerkt,
raadpleeg dienaangaande onze Privacyverklaring.
</p>

<p><h3>4. Wijzigingen</h3></p>
<p>BV FOUBERT IJS houdt zich het recht voor deze Cookieverklaring steeds te wijzingen</p>
<p>Deze Cookieverklaring werd het laatst gewijzigd op 3 januari 2023.</p>

<p><h3>5. Varia</h3></p>
<p>Indien één of meerdere clausules uit deze Cookieverklaring door een rechterlijke tussenkomst
gedeeltelijk of volledig nietig of niet-bindend wordt verklaard, heeft dit geen enkele invloed op
de geldigheid van de overige clausules of op de geldigheid van de gehele Cookieverklaring.
Indien BV FOUBERT IJS de bewuste clausule(s) wensen te wijzigen of vervangen, dient
de gewijzigde of nieuwe clausule zo nauw als mogelijk aan te sluiten bij de nietige of nietbindend verklaarde clausule(s).</p>
<p>Het nalaten van BV FOUBERT IJS om de strikte naleving van de bepalingen van deze
Cookieverklaring op te eisen, zal niet worden opgevat als een enige afstand of verwerping
daarvan.</p>

<p><h3>6. Toepasselijk recht</h3></p>
<p>Elke betwisting of vordering met betrekking tot de verwerking van persoonsgegevens en
Cookieverklaring of elk gegeven dat hierin vermeld staat, wordt door het Belgisch recht
beheerst en valt onder de exclusieve bevoegdheid van de hoven en rechtbanken van het
gerechtelijk arrondissement waar de zetel van BV FOUBERT IJS is gelegen (België).
</p>

<p><h3>7. Contact</h3></p>
<p>Indien u na het lezen van deze Cookieverklaring nog vragen of opmerkingen heeft in verband
met het beleid omtrent Cookies, kan u contact opnemen met BV FOUBERT IJS op het
adres Nobels-peelmanstraat 10, 9100 Sint-Niklaas of via het e-mailadres info@foubert.eu.
</p>
<p>Voor bijkomende algemene informatie betreffende cookies en de bescherming van
Persoonsgegevens kan u zich richten tot de Commissie voor de bescherming van de
persoonlijke levenssfeer met adres te 1000 Brussel, Drukpersstraat 35, tel: 02/274.48.00 of
via e-mail commission@privacycommission.be (www.privacycommission.be).</p>
            </div>
        )
    }
}

export default CookiePolicyView