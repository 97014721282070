import * as actionType from './TrackerActionType';

const initialState = {
    addressOptions: null,
    makeCallOnOpen: false,
    makeSilentCallOnOpen: false,
    availableNearby: false,
}

const trackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_ADDRESS_OPTIONS:
      return {...state, addressOptions: action.payload};
    case actionType.CLEAR_ADDRESS_OPTIONS:
      return {...state, addressOptions: null};
    case actionType.MAKE_CALL_ON_OPEN:
      return {...state, makeCallOnOpen: true};
    case actionType.DONT_MAKE_CALL_ON_OPEN:
      return {...state, makeCallOnOpen: false};
    case actionType.MAKE_SILENT_CALL_ON_OPEN:
      return {...state, makeSilentCallOnOpen: true};
    case actionType.DONT_MAKE_SILENT_CALL_ON_OPEN:
      return {...state, makeSilentCallOnOpen: false};
    case actionType.SET_AVAILABLE_NEARBY:
      return {...state, availableNearby: action.payload};
    case actionType.SET_LEGIT_CALL:
      return {...state, legitCall: true};
    case actionType.UNSET_LEGIT_CALL:
      return {...state, legitCall: false};
    default:
      return state
  }
}

export default trackerReducer;