import * as actionType from './OverlayActionType';

const initialState = {
    toast: null,
    dialog: null,
    loader: null,
    screenBlocker: false,
    hideHeader: false,
    hideHeaderLinks: false,
}

const overlayReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_TOAST:
      return {...state, toast: action.payload};
    case actionType.CLEAR_TOAST:
      return {...state, toast: null};

    case actionType.SET_DIALOG:
      return {...state, dialog: action.payload};
    case actionType.CLEAR_DIALOG:
      return {...state, dialog: null};

    case actionType.SET_LOADER:
        return {...state, loader: action.payload};
    case actionType.CLEAR_LOADER:
        return {...state, loader: null};

    case actionType.SHOW_SCREENBLOCKER:
      return {...state, screenBlocker: true};
    case actionType.HIDE_SCREENBLOCKER:
      return {...state, screenBlocker: false};

    case actionType.SET_HIDE_HEADER:
      return {...state, hideHeader: action.payload};
    case actionType.SET_HIDE_HEADER_LINKS:
      return {...state, hideHeaderLinks: action.payload};
    
    default:
      return state
  }
}

export default overlayReducer;