import React, {Component} from "react";
import { withRouter } from 'react-router-dom';

class Toast extends Component {
    constructor() {
        super();
    }
    
    render() {
        return (
            <div className={"btoast "+this.props.theme}>
                <p style={{marginBottom: "0", textAlign: "center"}}>{this.props.message}</p>
            </div>
        )
    }
}

export default Toast