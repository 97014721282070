import React, {Component} from "react"
import { Link } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'

class SecurexTrackMarker extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }
    
    render() {
        const kar = this.props.kar;

        const myIcon = L.icon({
            iconUrl: "/images/tracker/icon_kar_securex.png",
            iconSize: [28,28],
            iconAnchor: [14, 14],
            popupAnchor: [-0, -14],
            shadowUrl: "/images/tracker/icon_kar_securex.png",
            shadowSize: [28,28],
            shadowAnchor: [14, 14],
        });

        return (
            <div className="iconhand">
                <Marker
                    icon={myIcon}
                    key={"karmarker"+this.props.kar.id}
                    onClick={(event) => {this.props.onClick(this.props.trackid, kar.latitude, kar.longitude);}}
                    position={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    >
                    <Popup>
                        <b>IJskar {kar.title}</b>
                        {kar.available ? "" : <><br /><i>Niet beschikbaar: in pauze</i></>}
                        <p dangerouslySetInnerHTML={{__html: kar.subtitle}}></p>
                    </Popup>
                </Marker>
            </div>
        )
    }
}

export default SecurexTrackMarker