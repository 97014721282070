import React, {Component} from "react"
import { Link } from "react-router-dom";

class DialogBox extends Component {
    constructor() {
        super();
    }
    
    render() {
        return (
            <div className={"dialogBoxContainer "+this.props.theme}>
                <div className="dialogBox">
                    {this.props.title && <h2 style={{textAlign: "center", marginTop: "0", marginBottom: "0.5rem", display: "block"}}>{this.props.title}</h2>}
                    {this.props.message && <p style={{marginBottom: "0", textAlign: "center"}} dangerouslySetInnerHTML={{__html: this.props.message}}/>}

                    <div className="buttons">
                        { this.props.btn1Text && <a className={"btn "+(this.props.btn1Theme || '')} onClick={() => { this.props.response && this.props.response(this.props.btn1Title); }}>{this.props.btn1Text}</a> }
                        { this.props.btn2Text && <a className={"btn "+(this.props.btn2Theme || '')} onClick={() => { this.props.response && this.props.response(this.props.btn2Title); }}>{this.props.btn2Text}</a> }
                        { this.props.btn3Text && <a className={"btn "+(this.props.btn3Theme || '')} onClick={() => { this.props.response && this.props.response(this.props.btn3Title); }}>{this.props.btn3Text}</a> }
                    </div>
                </div>
                <div style={{left: "0", zIndex: "1031"}} onClick={() => { this.props.response && this.props.response("dismiss"); }} className="dark-overlay"></div>
            </div>

        )
    }
}

export default DialogBox