export const SET_TOAST = 'SET_TOAST';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const SET_DIALOG = 'SET_DIALOG';
export const CLEAR_DIALOG = 'CLEAR_DIALOG';

export const SET_LOADER = 'SET_LOADER';
export const CLEAR_LOADER = 'CLEAR_LOADER';

export const SHOW_SCREENBLOCKER = 'SHOW_SCREENBLOCKER';
export const HIDE_SCREENBLOCKER = 'HIDE_SCREENBLOCKER';

export const SET_HIDE_HEADER = 'SET_HIDE_HEADER';
export const SET_HIDE_HEADER_LINKS = 'SET_HIDE_HEADER_LINKS';