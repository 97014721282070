import React, {Component} from "react"
import { Link } from "react-router-dom";
import {store} from "../../App";
import { connect } from 'react-redux';
import Toast from './Toast';
import DialogBox from './DialogBox';
import Loader from './Loader';
import ScreenBlocker from './ScreenBlocker';
import { clearToast } from "../../Redux/OverlayActions";

class OverlayHandler extends Component {
    constructor() {
        super();

        this.scheduleClearToast = this.scheduleClearToast.bind(this);
    }

    scheduleClearToast(){
        setTimeout(
            () => {
                store.dispatch(clearToast());
            },
            5000
        );
    }
    
    render() {
        if(this.props.toast !== null) {
            this.scheduleClearToast();
        }

        return (
            <div>
                {this.props.toast !== null && <Toast
                    theme={this.props.toast.theme}
                    message={this.props.toast.message}
                />}
                {this.props.dialog !== null && <DialogBox
                    theme={this.props.dialog.theme}
                    title={this.props.dialog.title}
                    message={this.props.dialog.message}
                    btn1Text={this.props.dialog.btn1Text}
                    btn1Theme={this.props.dialog.btn1Theme}
                    btn1Title={this.props.dialog.btn1Title || "btn1"}
                    btn2Text={this.props.dialog.btn2Text}
                    btn2Theme={this.props.dialog.btn2Theme}
                    btn2Title={this.props.dialog.btn2Title || "btn2"}
                    btn3Text={this.props.dialog.btn3Text}
                    btn3Theme={this.props.dialog.btn3Theme}
                    btn3Title={this.props.dialog.btn3Title || "btn3"}
                    response={this.props.dialog.response}
                />}
                {this.props.loader !== null && <Loader
                    title={this.props.loader.title}
                    message={this.props.loader.message}
                />}
                {this.props.screenBlocker && <ScreenBlocker />}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        toast: state.overlay.toast || null,
        dialog: state.overlay.dialog || null,
        loader: state.overlay.loader || null,
        screenBlocker: state.overlay.screenBlocker || false,
    }
}

export default connect(mapStateToProps)(OverlayHandler);