import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import {store} from "../../App";
import { setHideHeaderLinks } from "../../Redux/OverlayActions";

class AboutAppView extends Component {
    constructor() {
        super();
        this.state = {
            redirectToTracker: false,
        };

        this.acceptConsent = this.acceptConsent.bind(this);
    }

    acceptConsent() {
        localStorage.setItem('consent', true);
        store.dispatch(setHideHeaderLinks(false));
        this.setState({
            redirectToTracker: true,
        });
    }

    render() {
        const $versionInfo = window.versionInfo;

        if (this.state.redirectToTracker === true) {
			return <Redirect push to='/' />
		}

        const headAccept = ((window.localStorage.getItem('consent') || false) ? null : 
            <div>
                <h2>Welkom</h2>
                <p>Om deze app te gebruiken moet je akkoord gaan met onze <Link to="/privacypolicy">privacyverklaring</Link> en <Link to="/cookiepolicy">cookievoorwaarden</Link>.</p>
                <div className="text-center" style={{margin: "2rem"}}>
                    <a onClick={this.acceptConsent} className="btn btn-primary btn-rounded" style={{color: 'white'}}>Aanvaard de voorwaarden</a>
                </div>
            </div>
        );

        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/aboutapp/header2.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                {headAccept}
                <h2>Over deze app</h2>
                <p>Welkom in de gloednieuwe Foubert-app.</p>
                <p>Met deze vernieuwing brengen we niet alleen onze frisse kleurrijke huisstijl naar jouw toestel maar nemen we ook enkele nieuwigheden mee: ijskarren die niet beschikbaar zijn (omwille van reservaties, pauze's of andere verplichtingen) zijn duidelijker niet beschikbaar op de kaart. Ook checkt de app nu vooraf of er beschikbare ijskarren in jouw buurt zijn alvorens je een oproep plaatst. Op die manier kan je beter inschatten of je effectief een ijskar mag verwachten en kunnen we teleurgestelde (kinder)traantjes vermijden. Op de kaart vind je nu ook andere locaties terug waar je het overheerlijke Foubert-ijs kan kopen. Ten slotte gebruikt deze app een vernieuwende technologie (PWA voor de nerds ;-) ) om deze beleving sneller te laten verlopen.</p>
                <p>Vragen of suggesties over de app zijn altijd welkom op info@foubert.eu</p>
                <p>Bavo Ceulemans,<br />Lead software developer</p>
                <p>Om deze app te gebruiken moet je akkoord gaan met onze <Link to="/privacypolicy">privacyverklaring</Link> en <Link to="/cookiepolicy">cookievoorwaarden</Link>.</p>
                <p>Buildversion: {$versionInfo} - App door ICEcorp (<a href="mailto:info@icecorp.be">info@icecorp.be</a>)</p>
            </div>
        )
    }
}

export default AboutAppView