import React, {Component} from "react"
import { Link } from "react-router-dom";

class Loader extends Component {
    constructor() {
        super();
    }
    
    render() {
        return (
            <div className={"loaderContainer "+this.props.theme}>
                <div className="loader">
                    {this.props.title && <h4 style={{textAlign: "center"}}>{this.props.title}</h4>}
                    <div className="lds-css ng-scope" style={{display: "inline-block", float: "left"}}><div style={{width: '100%', height: '100%'}} className="lds-double-ring"><div /><div /></div><style type="text/css" dangerouslySetInnerHTML={{__html: "@keyframes lds-double-ring {\n  0% {\n    -webkit-transform: rotate(0);\n    transform: rotate(0);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes lds-double-ring {\n  0% {\n    -webkit-transform: rotate(0);\n    transform: rotate(0);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n@keyframes lds-double-ring_reverse {\n  0% {\n    -webkit-transform: rotate(0);\n    transform: rotate(0);\n  }\n  100% {\n    -webkit-transform: rotate(-360deg);\n    transform: rotate(-360deg);\n  }\n}\n@-webkit-keyframes lds-double-ring_reverse {\n  0% {\n    -webkit-transform: rotate(0);\n    transform: rotate(0);\n  }\n  100% {\n    -webkit-transform: rotate(-360deg);\n    transform: rotate(-360deg);\n  }\n}\n.lds-double-ring {\n  position: relative;\n}\n.lds-double-ring div {\n  position: absolute;\n  width: 36px;\n  height: 36px;\n  top: 12px;\n  left: 12px;\n  border-radius: 50%;\n  border: 4px solid #000;\n  border-color: #62B5E5 transparent #62B5E5 transparent;\n  -webkit-animation: lds-double-ring 2.5s linear infinite;\n  animation: lds-double-ring 2.5s linear infinite;\n}\n.lds-double-ring div:nth-child(2) {\n  width: 30px;\n  height: 30px;\n  top: 15px;\n  left: 15px;\n  border-color: transparent #EF3340 transparent #EF3340;\n  -webkit-animation: lds-double-ring_reverse 2.5s linear infinite;\n  animation: lds-double-ring_reverse 2.5s linear infinite;\n}\n.lds-double-ring {\n  width: 60px !important;\n  height: 60px !important;\n  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);\n  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);\n}\n" }} /></div>
                    {this.props.message && <div style={{minHeight: "60px", display: "flex", alignItems: "center", marginLeft: "70px"}}><p style={{marginBottom: "0"}}>{this.props.message}</p></div>}
                </div>
                <div style={{left: "0", zIndex: "1031"}} className="dark-overlay"></div>
            </div>

        )
    }
}

export default Loader