import React, {Component} from "react"
import { Link } from "react-router-dom";

const divStyle = {
    position: "relative",
    display: "inline-block",
    margin: "0.5rem",
    backgroundColor: "white",
    padding: "10px",
    border: "5px solid black",
    borderRadius: "25px",
    textAlign: "center"
}

class LoginOption extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }
    
    render() {
        return (
            <div style={divStyle} onClick={(event) => {event.stopPropagation(); this.props.onClick(this.props.employeeid, this.props.name);}}>
                <span>{this.props.name}</span>
            </div>
        )
    }
}

export default LoginOption