import React, {Component} from "react"
import { Link } from "react-router-dom";

class ErrorImage extends Component {
    constructor() {
        super();
    }
    
    render() {
        const divStyle = {
            textAlign: "center",
        };

        const imgStyle = {
            marginTop: "2.5rem",
            maxWidth: "40%"
        }

        const bStyle = {
            display: "inline-block",
            marginTop: "1.5rem",
            fontSize: "250%"
        }

        const pStyle = {
            display: "inline-block",
            fontSize: "150%"
        }

        return (
            <div style={divStyle}>
                <img src="/images/cone_560.png" alt="A sad looking icecream cone" style={imgStyle}></img><br />
                <b style={bStyle}>{this.props.text}</b><br />
                <p style={pStyle}>{this.props.subtext}</p>
            </div>
        )
    }
}

export default ErrorImage