import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import ErrorImage from '../ErrorImage';
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';

class SetCookieView extends Component {
    constructor(match) {
        super();
        this.state = {
            apikey: match.match.params.apikey || null,
            division_id: match.match.params.division_id || null,
        };

        this.setCredentials = this.setCredentials.bind(this);
    }
    
    componentDidMount() {
        if (this.state.apikey != null && this.state.division_id != null) {
            this.setCredentials(this.state.apikey, this.state.division_id);
        } else {
            window.localStorage.removeItem('apikey');
            window.localStorage.removeItem('division_id');
        }
    }

    setCredentials(apikey, division_id) {
        window.localStorage.setItem('apikey', apikey);
        window.localStorage.setItem('division_id', division_id);
    }
    
    render() {
        const divStyle = {
            textAlign: "center",
        };

        const bStyle = {
            display: "inline-block",
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            fontSize: "250%"
        }

        const pStyle = {
            display: "inline-block",
            fontSize: "150%",
            marginBottom: "0rem",
        }

        const h2Style = {
            display: "inline-block",
            fontSize: "150%",
            marginTop: "1rem",
            marginBottom: "2rem"
        }

        return (
            <main>
                <div className="content container">
                    <div style={divStyle}>
                        <b style={bStyle}>OK!</b><br />
                        <p style={pStyle}>Je toestel is ingesteld</p>
                    </div>
                </div>
            </main>
        );
    }
}

export default SetCookieView