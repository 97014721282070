import * as actionType from './OverlayActionType';

export function setToast(msg){
    return {
        type: actionType.SET_TOAST,
        payload: msg
    }
};

export function clearToast(){
    return {
        type: actionType.CLEAR_TOAST
    }
};

export function setDialog(msg){
    return {
        type: actionType.SET_DIALOG,
        payload: msg
    }
};

export function clearDialog(){
    return {
        type: actionType.CLEAR_DIALOG
    }
};

export function setLoader(msg){
    return {
        type: actionType.SET_LOADER,
        payload: msg
    }
};

export function clearLoader(){
    return {
        type: actionType.CLEAR_LOADER
    }
};

export function showScreenBlocker(){
    return {
        type: actionType.SHOW_SCREENBLOCKER
    }
};

export function hideScreenBlocker(){
    return {
        type: actionType.HIDE_SCREENBLOCKER
    }
};

export function setHideHeader(hide){
    return {
        type: actionType.SET_HIDE_HEADER,
        payload: hide
    }
};

export function setHideHeaderLinks(hide){
    return {
        type: actionType.SET_HIDE_HEADER_LINKS,
        payload: hide
    }
};