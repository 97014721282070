import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import { dontMakeSilentCallOnOpen, setLegitCall, setAvailableNearby } from "../../Redux/TrackerActions";
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';
import { setToast, setDialog, clearDialog, setLoader, clearLoader, showScreenBlocker, hideScreenBlocker } from "../../Redux/OverlayActions";
import { askForPermissionToReceiveNotifications } from '../../push-notification';

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

class SilentCallButton extends Component {
    constructor() {
        super();
        this.state = {
            redirectToSilentAddress: false,

            showLocationFailedDialog: false,
            showLocationDeniedDialog: false,

            silentOproepGeplaatst: false,
        };

        this.handleClick = this.handleClick.bind(this);

        this.checkPermissionLocation = this.checkPermissionLocation.bind(this);

        this.preRequestPermissionLocation = this.preRequestPermissionLocation.bind(this);
        this.handlePreRequestPermissionLocationResponse = this.handlePreRequestPermissionLocationResponse.bind(this);

        this.requestLocation = this.requestLocation.bind(this);
        this.handleLocationRecieved = this.handleLocationRecieved.bind(this);
        this.handleLocationError = this.handleLocationError.bind(this);
        this.handleLocationDenied = this.handleLocationDenied.bind(this);
        this.handleLocationFailed = this.handleLocationFailed.bind(this);

        this.checkGeopositionAgainstStoredPosition = this.checkGeopositionAgainstStoredPosition.bind(this);

        this.makeLocationTranslation = this.makeLocationTranslation.bind(this);
        this.handleLocationTranslationFailed = this.handleLocationTranslationFailed.bind(this);
        this.setAdresStorage = this.setAdresStorage.bind(this);
        this.isStoredAddressValid = this.isStoredAddressValid.bind(this);

        this.handleSilentCall = this.handleSilentCall.bind(this);
        this.handleSilentCallResponse = this.handleSilentCallResponse.bind(this);

        this.watchdogTimeout = this.watchdogTimeout.bind(this);
        this.requestNotifications = this.requestNotifications.bind(this);
        this.postForm = this.postForm.bind(this);
        this.handleSubmitFailure = this.handleSubmitFailure.bind(this);
        this.handleSubmitSucces = this.handleSubmitSucces.bind(this);
        this.deleteForm = this.deleteForm.bind(this);
        this.handleDeleteFailure = this.handleDeleteFailure.bind(this);
        this.handleDeleteSucces = this.handleDeleteSucces.bind(this);
    }

    componentDidMount() {
        const date = new Date();
        const now = date.getTime();
        const silentCallActive =  (window.localStorage.getItem('token') || false) && (window.localStorage.getItem('silentCallActive') || false);

        if (silentCallActive > 0) {
            console.log(silentCallActive);
            this.setState({
                silentOproepGeplaatst: true,
            });
        }

        if (this.props.makeSilentCallOnOpen) {
            this.handleClick(true);
        }
        store.dispatch(dontMakeSilentCallOnOpen());
    }

    handleClick(skipLocationPopup = false) {
        this.props.handleMapClick();

        if (this.state.silentOproepGeplaatst) {
            this.deleteForm();
        } else {
            this.checkPermissionLocation(skipLocationPopup);
        }
    }

    checkPermissionLocation(skipLocationPopup = false) {
        const that = this;
        navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
            if('granted' === PermissionStatus.state) {
                //Location permission granted
                that.requestLocation();
            } else if('denied' === PermissionStatus.state) {
                //Location permission denied
                that.checkGeopositionAgainstStoredPosition(null, null);
            } else {
                //Location permission unknown
                if (skipLocationPopup) {
                    that.checkGeopositionAgainstStoredPosition(null, null);
                } else {
                    that.preRequestPermissionLocation();   
                }
            }
        });
        if (!navigator.permissions) {
            //This browser does not have the permissions API
            //Fallback to localstorage polyfill
            const geolocationState = window.localStorage.getItem('geolocation') || '';
            if('granted' === geolocationState) {
                //Location permission granted
                that.requestLocation();
            } else if('denied' === geolocationState) {
                //Location permission denied
                that.checkGeopositionAgainstStoredPosition(null, null);
            } else {
                //Location permission unknown
                if (skipLocationPopup) {
                    that.checkGeopositionAgainstStoredPosition(null, null);
                } else {
                    that.preRequestPermissionLocation();   
                }
            }
        }
    }

    preRequestPermissionLocation() {
        const that = this;
        store.dispatch(setDialog({
            title: "Wil je jouw locatie automatisch invullen?",
            message: "Als je toegang tot jouw locatie toestaat, kunnen we je adres automatisch invullen.",
            btn1Text: "Vul locatie automatisch in",
            btn1Title: "accept",
            btn1Theme: "primary",
            btn2Text: "Liever niet",
            btn2Title: "deny",
            response: that.handlePreRequestPermissionLocationResponse,
        }));
    }

    handlePreRequestPermissionLocationResponse(response) {
        store.dispatch(clearDialog());
        if (response == "accept") {
            this.requestLocation();
        } else if (response == "deny" || response == "dismiss") {
            // this.handleLocationDenied();
            this.deleteForm(true);
            store.dispatch(clearDialog());
            this.checkGeopositionAgainstStoredPosition(null, null);
        }
    }

    requestLocation() {
        store.dispatch(showScreenBlocker());
        navigator.geolocation.getCurrentPosition((geoposition) => {this.handleLocationRecieved(geoposition);}, (error) => {this.handleLocationError(error);});
    }

    handleLocationRecieved(geoposition) {
        store.dispatch(hideScreenBlocker());
        localStorage.setItem('geolocation', "granted");
        this.checkGeopositionAgainstStoredPosition(geoposition.coords.latitude, geoposition.coords.longitude);
    }

    handleLocationError(error) {
        store.dispatch(hideScreenBlocker());
        console.log(error);
        if (error.code == 1) {
            this.handleLocationDenied();
        } else {
            this.handleLocationFailed();
        }
    }

    handleLocationDenied() {
        localStorage.setItem('geolocation', "denied");
        const that = this;
        store.dispatch(setDialog({
            title: "Je locatie kon niet worden bepaald",
            message: "Je gaf geen toestemming om je adres automatisch te bepalen. Je zal je adres dus manueel moeten invullen.",
            theme: "error",
            btn1Text: "Ok",
            btn1Theme: "primary",
            response: () => {store.dispatch(clearDialog()); that.checkGeopositionAgainstStoredPosition(null, null)},
        }));
    }

    handleLocationFailed() {
        const that = this;
        store.dispatch(setDialog({
            title: "Je locatie kon niet worden bepaald",
            message: "Er ging iets mis tijdens het bepalen van je adres. Je zal je adres dus mogelijks manueel moeten invullen.",
            btn1Text: "Ok",
            btn1Theme: "primary",
            response: () => {store.dispatch(clearDialog()); that.checkGeopositionAgainstStoredPosition(null, null)},
        }));
    }

    checkGeopositionAgainstStoredPosition(lat, long) {
        if (!lat || !long) {
            //No GPS-latlong available
            const storedGpsLat = localStorage.getItem('gpslat') || null;
            const storedGpsLong = localStorage.getItem('gpslong') || null;
            if (!storedGpsLat || !storedGpsLong) {
                const storedLat = localStorage.getItem('latitude') || null;
                const storedLong = localStorage.getItem('longitude') || null;
                if (!storedLat || !storedLong) {
                    this.setState({
                        redirectToSilentAddress: true,
                    });
                } else {
                    if (this.isStoredAddressValid()) {
                        this.handleSilentCall();
                    } else {
                        store.dispatch(setToast({
                            message: "Je adres is niet volledig juist. Gelieve je adres aan te vullen",
                            theme: "error"
                        }));
                        this.setState({
                            redirectToSilentAddress: true,
                        });
                    }
                }
            } else {
                if (this.isStoredAddressValid()) {
                    this.handleSilentCall();
                } else {
                    store.dispatch(setToast({
                        message: "Je adres is niet volledig juist. Gelieve je adres aan te vullen",
                        theme: "error"
                    }));
                    this.setState({
                        redirectToSilentAddress: true,
                    });
                }
            }
        } else {
            //GPS returned latlong
            const storedGpsLat = localStorage.getItem('gpslat') || null;
            const storedGpsLong = localStorage.getItem('gpslong') || null;
            if (!storedGpsLat || !storedGpsLong) {
                this.makeLocationTranslation(lat, long);
            } else {
                const dist = getDistanceFromLatLonInKm(lat, long, storedGpsLat, storedGpsLong);
                if (dist < 0.5) {
                    if (this.isStoredAddressValid()) {
                        this.handleSilentCall();
                    } else {
                        store.dispatch(setToast({
                            message: "Je adres is niet volledig juist. Gelieve je adres aan te vullen",
                            theme: "error"
                        }));
                        this.setState({
                            redirectToSilentAddress: true,
                        });
                    }
                } else {
                    this.makeLocationTranslation(lat, long);
                }
            }
        }
    }

    isStoredAddressValid() {
        return (window.localStorage.getItem('straat') != "" && window.localStorage.getItem('nr') != "" && window.localStorage.getItem('gemeente') != "" && window.localStorage.getItem('postcode') != "" && window.localStorage.getItem('land') != "" && window.localStorage.getItem('placeid') != "");
    }

    makeLocationTranslation(gpslat, gpslong) {
        store.dispatch(setLoader({
            title: "Even geduld...",
            message: "We vragen je locatie op",
        }));

        let formData = new FormData();
        formData.append('latitude', gpslat);
        formData.append('longitude', gpslong);

        fetch(process.env.REACT_APP_API_HREF+"legacycopy.php?action=get_adrestranslation",{
            method: 'POST', 
            body: formData,
        }).then(function(response) {
            return response.json();
        }).then(data => {
            console.log(data);
            if (data.status == 200) {
                store.dispatch(clearLoader());

                const result = data.result;
                this.setAdresStorage(gpslat, gpslong, result.adres, result.latitude, result.longitude, result.Straat, result.Nr, result.Gemeente, result.Postcode, result.Land, result.place_id);
                this.handleSilentCall();
            } else {
                this.handleLocationTranslationFailed();
            }
        }).catch(error => {
            console.log(error);
            this.handleLocationTranslationFailed();
        });
    }

    handleLocationTranslationFailed() {
        store.dispatch(clearLoader());

        const that = this;
        store.dispatch(setDialog({
            title: "Je locatie kon niet worden bepaald",
            message: "Er ging iets mis tijdens het bepalen van je adres. Je zal je adres dus mogelijks manueel moeten invullen.",
            btn1Text: "Ok",
            btn1Theme: "primary",
            response: () => {store.dispatch(clearDialog()); that.checkGeopositionAgainstStoredPosition(null, null)},
        }));
    }

    setAdresStorage(gpslat, gpslong, adres, latitude, longitude, straat, nr, gemeente, postcode, land, place_id) {
        window.localStorage.setItem('gpslat', gpslat);
        window.localStorage.setItem('gpslong', gpslong);
        window.localStorage.setItem('adres', adres);
        window.localStorage.setItem('latitude', latitude);
        window.localStorage.setItem('longitude', longitude);
        window.localStorage.setItem('straat', straat);
        window.localStorage.setItem('nr', nr);
        window.localStorage.setItem('gemeente', gemeente);
        window.localStorage.setItem('postcode', postcode);
        window.localStorage.setItem('land', land);
        window.localStorage.setItem('placeid', place_id);
    }

    handleSilentCall() {
        store.dispatch(setAvailableNearby(true));
        // const address = (localStorage.getItem('straat') || "")+" "+(localStorage.getItem('nr') || "")+", "+(localStorage.getItem('postcode') || "")+" "+(localStorage.getItem('gemeente') || "")+", "+(localStorage.getItem('land') || "");
        const address = (window.localStorage.getItem('adres') || "");
        store.dispatch(setDialog({
            title: "Verwittig mij!",
            message: "Wil je een notificatie krijgen als er een ijskar is in de buurt van <br /><b>\""+address+"\"</b>?<br /><br />Je krijgt deze melding elke dag maximaal 1 keer tot je ze hier terug afzet.",
            btn1Text: "Verwittig mij!",
            btn1Theme: "primary",
            btn1Title: "roep",
            btn2Text: "Adres wijzigen",
            btn2Title: "adres",
            btn3Text: "Annuleer",
            btn3Title: "annuleer",
            response: (response) => { this.handleSilentCallResponse(response);},
        }));
    }

    handleSilentCallResponse(response) {
        if (response == "roep") {
            store.dispatch(clearDialog()); 
            store.dispatch(setLegitCall());
            this.requestNotifications();
        } else if (response == "adres") {
            store.dispatch(clearDialog()); 
            this.setState({
                redirectToSilentAddress: true,
            });
        } else if (response == "annuleer") {
            store.dispatch(clearDialog());
            this.deleteForm(true);
        }
    }

    async watchdogTimeout() {
        return new Promise(resolve => setTimeout(() => resolve({
            status: "FAIL",
            error: "Watchdog timeout"
        }), 2200));
    }

    async requestNotifications() {
        store.dispatch(showScreenBlocker());
        const permissionResponse = await Promise.race([askForPermissionToReceiveNotifications, this.watchdogTimeout].map(f => f()));
        console.log(permissionResponse);
        store.dispatch(hideScreenBlocker());

        let token = window.localStorage.getItem('token') ?? '';
        if (permissionResponse.status == "SUCCESS") {
            window.localStorage.setItem('notifications', "granted");
            token = permissionResponse.token;
            this.postForm(token);
        } else {
            if (token != '') {
                window.localStorage.setItem('notifications', "granted");
                this.postForm(token);
            } else {
                window.localStorage.setItem('notifications', "denied");
                store.dispatch(setToast({
                    message: "Er ging iets mis!",
                    theme: "error"
                }));
            }
        }
    }

    postForm(token) {
        store.dispatch(setLoader({
            title: "Even geduld...",
            message: "Je notificatie wordt aangevraagd",
        }));

        window.localStorage.setItem('token', token);

        let formData = new FormData();
        formData.append('gpslat', (window.localStorage.getItem('gpslat') || ''));
        formData.append('gpslong', (window.localStorage.getItem('gpslong') || ''));
        formData.append('latitude', (window.localStorage.getItem('latitude') || ''));
        formData.append('longitude', (window.localStorage.getItem('longitude') || ''));
        formData.append('straat', (window.localStorage.getItem('straat') || ''));
        formData.append('nr', (window.localStorage.getItem('nr') || ''));
        formData.append('gemeente', (window.localStorage.getItem('gemeente') || ''));
        formData.append('postcode', (window.localStorage.getItem('postcode') || ''));
        formData.append('land', (window.localStorage.getItem('land') || ''));
        formData.append('placeid', (window.localStorage.getItem('placeid') || ''));
        formData.append('adres', (window.localStorage.getItem('adres') || ''));
        formData.append('udid', (window.localStorage.getItem('udid') || ''));
        formData.append('token', token);
        formData.append('versioninfo', window.versionInfo || "");

        fetch(process.env.REACT_APP_API_HREF+"legacycopy.php?action=add_silent_oproep",{
            method: 'POST', 
            body: formData,
        }).then(function(response) {
            store.dispatch(clearLoader());
            return response.json();
        }).then(data => {
            if (data.status == 201) {
                this.handleSubmitSucces();
            } else {
                this.handleSubmitFailure();
            }
        }).catch(error => {
            this.handleSubmitFailure();
        });
    }

    handleSubmitFailure() {
        store.dispatch(setToast({
            message: "Er ging iets mis bij het aanvragen van je notificatie. Probeer het later opnieuw.",
            theme: "error"
        }));
    }

    handleSubmitSucces() {
        window.localStorage.setItem('silentCallActive', 1);
        this.setState({
            silentOproepGeplaatst: true,
        });

        store.dispatch(setToast({
            message: "We zullen je een notificatie sturen als er een ijskar in jouw buurt komt!",
        }));
    }

    deleteForm(noConfirm = false) {
        if (!noConfirm) {
            store.dispatch(setLoader({
                title: "Even geduld...",
                message: "We verwijderen je notificatieaanvraag",
            }));
        }

        window.localStorage.getItem('token')

        let formData = new FormData();
        formData.append('udid', (window.localStorage.getItem('udid') || ''));
        formData.append('token', window.localStorage.getItem('token'));
        formData.append('versioninfo', window.versionInfo || "");

        fetch(process.env.REACT_APP_API_HREF+"legacycopy.php?action=remove_silent_oproep",{
            method: 'POST', 
            body: formData,
        }).then(function(response) {
            store.dispatch(clearLoader());
            return response.json();
        }).then(data => {
            if (data.status == 200) {
                if (!noConfirm) this.handleDeleteSucces();
            } else {
                if (!noConfirm) this.handleDeleteFailure();
            }
        }).catch(error => {
            if (!noConfirm) this.handleDeleteFailure();
        });
    }

    handleDeleteFailure() {
        store.dispatch(setToast({
            message: "Er ging iets mis bij het verwijderen van je notificatie. Probeer het later opnieuw.",
            theme: "error"
        }));
    }

    handleDeleteSucces() {
        window.localStorage.setItem('silentCallActive', 0);
        this.setState({
            silentOproepGeplaatst: false,
        });

        store.dispatch(setToast({
            message: "Je notificatie is uitgezet. Je kan ze hier terug aanvragen.",
        }));
    }
    
    render() {
        const buttonSrc = (this.state.silentOproepGeplaatst ? 'images\\tracker\\icon_silent_button_notavailable.png' : 'images\\tracker\\icon_silent_button.png');
        // const buttonTextSrc = 'images\\tracker\\icon_roep.png';

        if (this.state.redirectToSilentAddress === true) {
            return <Redirect to='/silentaddress' />
        }

        return (
            <div>
                <img src={buttonSrc} alt='Waarschuw mij als er een ijskar in de buurt is!' className='iconhand silentCallButton' onClick={(event) => {event.stopPropagation(); this.handleClick();}}/>
                {/* <img src={buttonTextSrc} alt='Roep de IJskar!' className='iconhand trackerButtonText' onClick={(event) => {event.stopPropagation(); this.handleClick();}}/> */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        makeSilentCallOnOpen: state.tracker.makeSilentCallOnOpen ?? false,
    }
}

export default connect(mapStateToProps)(SilentCallButton);