import React, {Component} from "react"
import { Link } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'

class POIMarker extends Component {
    constructor() {
        super();
    }
    
    render() {
        const poi = this.props.poi;

        const myIcon = L.icon({
            iconUrl: (
                this.props.poi.icon_type == "HREF" ? this.props.poi.icon
                : "undefined"),
            iconSize: [28,28],
            iconAnchor: [14, 14],
            popupAnchor: [-0, -14],
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
        });

        return (
            <div className="iconhand">
                <Marker
                    icon={myIcon}
                    key={"poimarker"+this.props.poi.id}
                    onClick={(event) => {this.props.onClick(this.props.trackid, poi.latitude, poi.longitude);}}
                    position={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    >
                    <Popup>
                        <b>{poi.title}</b>
                        <p dangerouslySetInnerHTML={{__html: poi.subtitle}}></p>
                    </Popup>
                </Marker>
            </div>
        )
    }
}

export default POIMarker