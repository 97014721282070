import React, {Component} from "react"
import { Link } from "react-router-dom";

class ScreenBlocker extends Component {
    constructor() {
        super();
    }
    
    render() {
        return (
            <div>
                <div style={{left: "0", zIndex: "1031"}} className="dark-overlay"></div>
            </div>

        )
    }
}

export default ScreenBlocker