import * as actionType from './TrackerActionType';

export function setAddressOptions(options){
    return {
        type: actionType.SET_ADDRESS_OPTIONS,
        payload: options
    }
};

export function clearAddressOptions(){
    return {
        type: actionType.CLEAR_ADDRESS_OPTIONS
    }
};

export function setAvailableNearby(availableNearby){
    return {
        type: actionType.SET_AVAILABLE_NEARBY,
        payload: availableNearby
    }
};

export function setLegitCall(){
    return {
        type: actionType.SET_LEGIT_CALL
    }
};

export function unsetLegitCall(){
    return {
        type: actionType.UNSET_LEGIT_CALL
    }
};

export function makeCallOnOpen(){
    return {
        type: actionType.MAKE_CALL_ON_OPEN
    }
};

export function dontMakeCallOnOpen(){
    return {
        type: actionType.DONT_MAKE_CALL_ON_OPEN
    }
};

export function makeSilentCallOnOpen(){
    return {
        type: actionType.MAKE_SILENT_CALL_ON_OPEN
    }
};

export function dontMakeSilentCallOnOpen(){
    return {
        type: actionType.DONT_MAKE_SILENT_CALL_ON_OPEN
    }
};