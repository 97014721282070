import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import ErrorImage from '../ErrorImage';
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';

class SetTokenView extends Component {
    constructor(match) {
        super();

        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        this.state = {
            token: token || null,
        };
    }
    
    componentDidMount() {
        if (this.state.token != null) {
            window.localStorage.setItem('token', this.state.token);
            window.localStorage.setItem('notifications', "granted");
        }
    }
    
    render() {
        return <Redirect push to='/' />
    }
}

export default SetTokenView